var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review-list"},[_c('SectionHeader',{attrs:{"title":"1: Select a plan to quality assure"}}),_c('div',{staticClass:"ehcp-list"},[_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.newAssignments),function(assignment,index){return _c('tr',{key:index,class:{
              active:
                _vm.currentAssignment && _vm.currentAssignment.id === assignment.id,
            },on:{"click":function($event){return _vm.didClickStartReview(assignment)}}},[_c('td',[_vm._v(_vm._s(assignment.plan.internalId))]),_c('td',[_vm._v(_vm._s(assignment.plan.keyStage))]),_c('td',[_vm._v(_vm._s(assignment.plan.primaryNeed))]),_c('td',[_vm._v(_vm._s(assignment.plan.settingName))]),_c('td',[_vm._v(_vm._s(assignment.plan.settingType))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(assignment.plan.finalisedDate)))]),_c('td',[_vm._v(_vm._s(assignment.plan.currentSocialCare))])])}),0)])])]),_c('hr'),_c('SectionHeader',{attrs:{"title":"2: Reviews in progress:"}}),_c('div',{staticClass:"ehcp-list"},[_c('div',{staticClass:"table-wrapper"},[_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.existingReviews),function(assignment){return _c('tr',{key:assignment.id,class:{
              active:
                _vm.currentAssignment && _vm.currentAssignment.id === assignment.id
            },on:{"click":function($event){return _vm.didClickContinueReview(assignment)}}},[_c('td',[_vm._v(_vm._s(assignment.plan.internalId))]),_c('td',[_vm._v(_vm._s(assignment.plan.keyStage))]),_c('td',[_vm._v(_vm._s(assignment.plan.primaryNeed))]),_c('td',[_vm._v(_vm._s(assignment.plan.settingName))]),_c('td',[_vm._v(_vm._s(assignment.plan.settingType))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(assignment.plan.finalisedDate)))]),_c('td',[_vm._v(_vm._s(assignment.plan.currentSocialCare))]),_c('td',[_vm._v(_vm._s(assignment.lastTheme))])])}),0)])])]),_c('SectionFooter',{attrs:{"showBackButton":false,"nextButtonText":"Proceed to review","canClickNextButton":!!_vm.currentAssignment && !_vm.hasClickedNext},on:{"nextClicked":_vm.startAudit}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("*C/YP ID")]),_c('th',[_vm._v("Key Stage")]),_c('th',[_vm._v("Primary Need")]),_c('th',[_vm._v("Setting Name")]),_c('th',[_vm._v("Setting Type")]),_c('th',[_vm._v("Review Date")]),_c('th',[_vm._v("Current Social Care")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("*C/YP ID")]),_c('th',[_vm._v("Key Stage")]),_c('th',[_vm._v("Primary Need")]),_c('th',[_vm._v("Setting Name")]),_c('th',[_vm._v("Setting Type")]),_c('th',[_vm._v("Review Date")]),_c('th',[_vm._v("Current Social Care")]),_c('th',[_vm._v("Most Recent")])])])
}]

export { render, staticRenderFns }