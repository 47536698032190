<template>
  <div class="plan-container plan-container__summary">
    <SectionHeader title="Summary" />
    <p class="subtitle">
      Based on your selections, the overall grade of the plan is
      <span class="grading-result">{{ grading | formatOutcome }}</span>
      <br />
    </p>
    <div class="plan-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>Theme</th>
              <th>Calculated Grade</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="theme in themes" :key="theme.theme">
              <td>{{ theme.title }}</td>
              <td>
                {{
                  answers.find((answer) => answer.theme === theme.theme) | formatGrade
                }}
              </td>
              <td><span class="colour-code"></span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="aduit-actions">
          <h4> Actions</h4>
          <div class="actions">
          <ul class="actions-list" v-if="reviewActions.length > 0" v-for="item of reviewActions">
            <li @click="handleDeleteAction(item)" class="actions-list-item">
              <i class="fas fa-solid fa-trash trash"></i>
              <p>{{item}}</p></li>
          </ul>
          <form class="action-form">
          <i class="fas fa-solid fa-plus plus"></i> <input v-model="currentAction" @keydown.enter.prevent="handleAction()" type="text" name="action" class="audit"/>
          </form>
          </div>
        </div>


    </div>
    <SectionFooter
      nextButtonText="Submit"
      @backClicked="navigateToPreviousSection"
      :canClickNextButton="true"
      @nextClicked="submitFinal"
    />
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import SectionFooter from '@/components/SectionFooter'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AuditSummary',
  components: { SectionHeader, SectionFooter },
  data() {
    return {
      currentAction: ""
    }
  },
  computed: {
    ...mapGetters({
      themes: 'criteria/themes',
      answers: 'review/answers',
      reviewId: 'review/reviewId',
      actions: 'review/actions',

    }),

   reviewActions () {
    if (!this.actions) {
        return []
    }

    return this.actions
    },
    grading () {
      try {
        return this.answers.reduce((sum, answer) => sum += answer.outcome, 0)
      } catch(error) {
        console.log(error)
      }
    },
    reviewLink () {
      return `${process.env.VUE_APP_SITE_URL}/opinion/${this.reviewId}`
    }
  },
  methods: {
    ...mapActions({
      setReviewComplete: 'review/setReviewComplete',
      fetchAssignmentsForCurrentUser: 'assignment/fetchAssignmentsForCurrentUser',
      updateReview: 'review/updateReviewActions',
      fetchReviews: 'review/fetchReviews'
    }),
    navigateToPreviousSection () {
      
      // TODO: MF We should not go back to this page as the ui breaks in the reviewtemplate once you start editing
      // this.$router.push({
      //   name: 'PerformReview',
      //   params: {
      //     id: 6
      //   }
      // })

      this.$router.push({ 
        name: 'MonitoringTheme', 
        params: { id: 6 }
      })
    },
    async submitFinal () {
      try {
        await this.setReviewComplete()
        alert('Thank you. Your grading was submitted successfully.')
        await this.fetchAssignmentsForCurrentUser()
        this.$router.push({ name: 'ReviewList' })
      } catch (error) {
        alert('Sorry, there was an error submitting your review. All your data is saved. Please try again.')
      }
    },
    async handleAction() {
      if(this.currentAction.length > 0) {
        this.reviewActions.push(this.currentAction)

        this.currentAction = "";
        
        await this.updateReview(this.reviewActions)
        await this.fetchReviews(this.reviewId)
    }
    },
    async handleDeleteAction(item) {
      this.reviewActions.splice(this.reviewActions.indexOf(item), 1)
     await this.updateReview(this.reviewActions);
    },
  
  },
  async mounted() {
     await this.fetchReviews(this.reviewId)
    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.plan-container__summary {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.subtitle {
  margin-bottom: 0.4rem;
}

.grading-result {
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 600;
  line-height: 2rem;
}

.disclaimer {
  padding-left: $padding1x;
  padding-top: $padding1x;
  font-weight: 500;
}

.colour-code {
  height: 30px;
  width: 30px;
  border-radius: 15px;
}

p.link a {
  padding-bottom: 1rem;
}

.is-failed {
  background: "#ec685d";
}

.action-form {
  width: 100%;
  background-color: #ebeeed;
  display: flex;
  justify-content: space-between  ;
  
}
.action-form input {
 width: 98%;
}

.plus{
  border: 2px solid black;
 border-radius: 50%;
  padding: 5px;
  margin: 5px;
}
.actions-list{
  list-style-type: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.actions-list-item{
  display: flex;
  background-color: #ebeeed;
  align-items: center;
  border-radius: 5px;
  margin: 10px 0;
  

}
.trash{
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}

.trash:hover{
  color: red;
  
}


</style>
