<template>
  <DashboardLayout>
    <template v-slot:sidebar>
      <SideBar :items="sidebarItems" />
    </template>
    <template v-slot:topnav>
      <TopNav :role="role" />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/components/common/DashboardLayout.vue'
import SideBar from '@/components/nav/SideBar.vue'
import TopNav from '@/components/nav/TopNav.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: { DashboardLayout, SideBar, TopNav },
  data () {
    return {
      sidebarItems: [
        {
          text: 'Select AR',
          route: '/review/select',
        },
        {
          text: 'Confirm',
          route: '/review/confirm',
          disabled: true
        },
        {
          text: 'Theme 1 - Timeliness & Quality',
          route: '/review/theme/1',
          disabled: true
        },
        {
          text: 'Theme 2 - Meeting Needs',
          route: '/review/theme/2',
          disabled: true
        },
        {
          text: 'Theme 3 - Outcomes',
          route: '/review/theme/3',
          disabled: true
        },
        {
          text: 'Theme 4 - Provision',
          route: '/review/theme/4',
          disabled: true
        },
        {
          text: 'Theme 5 - Post-annual review',
          route: '/review/theme/5',
          disabled: true
        },
        {
          text: 'Theme 6 - Outcomes monitoring',
          route: '/review/theme/6',
          disabled: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      role: 'user/role'
    })
  },
  methods: {
    ...mapActions({
      fetchAssignmentsForCurrentUser: 'assignment/fetchAssignmentsForCurrentUser'
    })
  },
  async mounted () {
    await this.fetchAssignmentsForCurrentUser()
  }
}
</script>
