<template>
  <div class="review-template-container">
    <div class="title">
      <h3 class="section-title">{{ currentTheme.title }}</h3>
      <p class="section-subtitle">{{ currentTheme.description }}</p>
    </div>
    <div class="grades-row">
      <GradeButton
        v-for="theme in ['gold', 'silver', 'bronze']"
        :key="theme"
        :grade="theme"
        :selected="currentScore(theme)"
        activated="false"
      />
    </div>
    <div>
      <textarea
        class="textarea"
        placeholder="Please provide any other comments on the quality of this section"
        v-model="comments"
      />
    </div>
    <div class="evaluation">
      <p class="subtitle__bold">QA criteria</p>
      <QuestionList>
        <QuestionListItem
          v-for="(question, index) in themeCriteria"
          :key="question.text"
          :index="index"
          :question="question"
          :answer="answerForQuestion(index)"
          :dbComment="commentsForQuestion(index)"
          @did-answer="didSelectAnswer"
        />
      </QuestionList>
    </div>
    <SectionFooter
      nextButtonText="Next section"
      @backClicked="navigateToPreviousSection"
      @nextClicked="navigateToNextSection"
    />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { mapGetters, mapActions } from 'vuex'
import _ from "lodash"

import SectionFooter from '@/components/SectionFooter'
import GradeButton from '@/components/layout/GradeButton.vue'
import QuestionList from '@/views/reviewer/QuestionList.vue'
import QuestionListItem from '@/views/reviewer/QuestionListItem.vue'

export default {
  name: 'ReviewSectionTemplate',
  props: {
    id: Number,
  },
  components: { GradeButton, QuestionList, QuestionListItem, SectionFooter },
  computed: {
    ...mapGetters({
      themes: 'criteria/themes',
      answers: 'review/answers'
    }),
    currentTheme () {
      return this.themes[this.id - 1]
    },
    themeCriteria () {
      return this.currentTheme.questions
    },
    themeAnswers () {
      return [...this.answers].find(a => a.theme === this.id) || { criteria: [] }
    },
    gradingScores () {
      // TODO: Make this pull from server
      return {
        gold: { min: 2.26, max: 100 },
        silver: { min: 1.31, max: 2.25 },
        bronze: { min: 0, max: 1.3 }
      }
    },
    comments: {
      set (text) {
        this.updateThemeComment(text)
      },
      get () {
        return this.themeAnswers
          ? this.themeAnswers.comments
          : null
      }
    }
  },
  methods: {
    ...mapActions({ 
      submitAnswer: 'review/submitAnswer', 
      addComment: 'review/addComment',
    }),
    ...mapGetters({
      reviewId: 'review/reviewId'
    }),
    updateThemeComment: _.debounce(function (text) {
      this.addComment({ theme: this.id, text })
    }, 300),
    async didSelectAnswer (payload) {
      try {
        const res = await this.submitAnswer(payload)

        return res
      } catch (error) {

        alert('Something went wrong. Please start the review again.')

        this.$router.push({ name: 'ReviewList' })
      }
    },
    answerForQuestion (index) {
      const hasAnswer = this.themeAnswers.criteria.find((a) => a.questionIndex === index)

      // If we don't have a answer or score then we should return false
      // We have to type check as 0 is a acceptable score.
      if (hasAnswer) {
        if (hasAnswer.hasOwnProperty('score') && typeof hasAnswer.score === 'number') {
          return hasAnswer.score
        }

        if (typeof hasAnswer.score === 'boolean') {
          return hasAnswer.score
        }
      }

      return false
    },
    commentsForQuestion (index) {
      const hasAnswer = this.themeAnswers.criteria.find((a) => a.questionIndex === index)

      return (hasAnswer) ? hasAnswer.comments : ""
    },
    currentScore (theme) {
      return this.themeAnswers
        ? this.gradingScores[theme].min <= this.themeAnswers.outcome && this.themeAnswers.outcome <= this.gradingScores[theme].max
        : false
    },
    canNavigate () {
      const numberOfQuestions = this.themeCriteria.length

      const arrayOfAnswers = this.themeAnswers.hasOwnProperty('criteria')
        ? this.themeAnswers.criteria
        : []

      if (arrayOfAnswers.length < numberOfQuestions) {
        const listItems = document.getElementsByClassName('question-item-wrapper')
        
        this.themeCriteria.forEach((question, index) => {
          if (!arrayOfAnswers.some(answer => answer.questionIndex === index)) {
            
            listItems[index].style.backgroundColor = 'red'
            listItems[index].style.color = 'white'
            
            setTimeout(() => {
              listItems[index].removeAttribute('style')
            }, 900)
          }
        })
        return false
      }

      const allNotApplicable = arrayOfAnswers.filter(({ text }) =>
        text && text.includes("Not applicable")
      )

      if (allNotApplicable.length >= numberOfQuestions) {
        alert("All questions can not be answered Not applicable" )

        return false;
      }

      const incompletePartial = arrayOfAnswers.filter(({ text, comments }) =>
        text && text.includes("partial") && !(comments && comments.length)
      )

      incompletePartial.map(({ questionIndex }) => console.log(questionIndex))

      if (incompletePartial.length) {
        const listItems = [...document.getElementsByClassName('question-item-wrapper')]

        incompletePartial.forEach(({ questionIndex }) => {
          
          if (typeof questionIndex === 'number') {
            const listItem = listItems[questionIndex]

            listItem.style.backgroundColor = 'red'
            listItem.style.color = 'white'

            setTimeout(() => {
              listItems[questionIndex].removeAttribute('style')
            }, 5000)
          }
        })

        const question = incompletePartial.map(({ questionIndex }) => { 
          if (typeof questionIndex === 'number') {
            return questionIndex + 1
          }
        })

        alert("Partially complete answers need an additional reason. Questions: " + question.join(", ") )

        return false;
      }

      return true
    },
    async refreshReview() {
      try {
        const reviewID = this.reviewId()
        
        await this.$store.dispatch('review/refreshReview', reviewID)

        return true
      } catch (error) {
        alert('Something went wrong. Please start the review again.')

        this.$router.push({ name: 'ReviewList' })
      }
    },
    async navigateToPreviousSection () {
      const currentThemeIndex = Number(this.id)

      // Refresh reviewState to ensure we have the latest data in the view
      try {
        const success = await this.refreshReview()

        if (currentThemeIndex === 1) {
          this.navigateToReviewConfirmation()
        } else {
          this.navigateToTheme(currentThemeIndex - 1)
        }

      } catch (error) {
        alert('Something went wrong. Please start the review again.')

        this.$router.push({ name: 'ReviewList' })
      }
    },
    async navigateToNextSection () {
      try {
        // Refresh reviewState to ensure we have the latest data in the view
        const success = await this.refreshReview()

        if (!this.canNavigate()) {
          return
        }
        
        const currentThemeIndex = Number(this.id)

        if (currentThemeIndex === 6) {
          this.navigateToOutcomesSelection()
        } else if (currentThemeIndex === 5) {
          this.navigateToMonitoringTheme()
        } else {
          this.navigateToTheme(currentThemeIndex + 1)
        }
      } catch (error) {
        alert('Something went wrong. Please start the review again.')

        this.$router.push({ name: 'ReviewList' })
      }
    },
    navigateToMonitoringTheme () {
      this.$router.push({ name: 'MonitoringTheme' })
    },
    navigateToOutcomesSelection () {
      this.$router.push({ name: 'ReviewSummary' })
    },
    navigateToReviewConfirmation () {
      this.$router.push({ name: 'ReviewConfirmation' })
    },
    navigateToTheme (index) {
      this.$router.push({
        name: 'PerformReview',
        params: {
          id: Number(index)
        }
      })
    }
  },
  async mounted () {
    try {
      await this.refreshReview()
    } catch (error) {
      alert('Something went wrong. Please start the review again.')

      this.$router.push({ name: 'ReviewList' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.review-template-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.title {
  .section-title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .section-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.4rem;
    &--bold {
      font-weight: 600;
    }
  }
}

.textarea {
  border-radius: $content-border-radius;
  border: $content-border;
  height: 100%;
  font-size: 1rem;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
}
textarea,
textarea::placeholder {
  font-family: AllRoundGothicBook;
}

.grades-row {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.evaluation {
  margin-top: 1rem;
  .subtitle {
    &__bold {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
  }
}
</style>