<template>
  <div class="review-list">
    <SectionHeader title="1: Select a plan to quality assure" />
    <!-- List of Plans -->
    <div class="ehcp-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>*C/YP ID</th>
              <th>Key Stage</th>
              <th>Primary Need</th>
              <th>Setting Name</th>
              <th>Setting Type</th>
              <th>Review Date</th>
              <th>Current Social Care</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(assignment, index) in newAssignments"
              :key="index"
              @click="didClickStartReview(assignment)"
              :class="{
                active:
                  currentAssignment && currentAssignment.id === assignment.id,
              }"
            >
              <td>{{ assignment.plan.internalId }}</td>
              <td>{{ assignment.plan.keyStage }}</td>
              <td>{{ assignment.plan.primaryNeed }}</td>
              <td>{{ assignment.plan.settingName }}</td>
              <td>{{ assignment.plan.settingType }}</td>
              <td>{{ assignment.plan.finalisedDate | formatDate }}</td>
              <td>{{ assignment.plan.currentSocialCare }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr />
    <SectionHeader title="2: Reviews in progress:" />
    <div class="ehcp-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>*C/YP ID</th>
              <th>Key Stage</th>
              <th>Primary Need</th>
              <th>Setting Name</th>
              <th>Setting Type</th>
              <th>Review Date</th>
              <th>Current Social Care</th>
              <th>Most Recent</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="assignment in existingReviews"
              :key="assignment.id"
              @click="didClickContinueReview(assignment)"
              :class="{
                active:
                  currentAssignment && currentAssignment.id === assignment.id
              }"
            >
              <td>{{ assignment.plan.internalId }}</td>
              <td>{{ assignment.plan.keyStage }}</td>
              <td>{{ assignment.plan.primaryNeed }}</td>
              <td>{{ assignment.plan.settingName }}</td>
              <td>{{ assignment.plan.settingType }}</td>
              <td>{{ assignment.plan.finalisedDate | formatDate }}</td>
              <td>{{ assignment.plan.currentSocialCare }}</td>
              <td>{{ assignment.lastTheme }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <SectionFooter
      :showBackButton="false"
      nextButtonText="Proceed to review"
      :canClickNextButton="!!currentAssignment && !hasClickedNext"
      @nextClicked="startAudit"
    />
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import SectionFooter from '@/components/SectionFooter'
import DateFilterMixin from '@/_mixins/date-filter.mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ReviewList',
  mixins: [DateFilterMixin],
  components: { SectionHeader, SectionFooter },
  data () {
    return {
      didChooseExistingReview: false,
      hasClickedNext: false,
    }
  },
  async beforeMount () {
    this.$store.dispatch('assignment/setCurrentAssignment')

    // We have to ensure state is up to date before we can filter the assignments
    await this.$store.dispatch('assignment/fetchAssignmentsForCurrentUser')
    await this.$store.dispatch('assignment/fetchAssignments')
  },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignmentsForCurrentUser',
      currentAssignment: 'assignment/currentAssignment',
      reviews: 'assignment/reviews',
    }),  
    newAssignments () {
      return this.getNewAssignments()
    },
    existingReviews () {
      return this.getExistingReviews()
    }
  },
  methods: {
    ...mapActions({
      setCurrentAssignment: 'assignment/setCurrentAssignment',
      continueReview: 'review/continueReview',
    }),
    getNewAssignments () {
      return [...this.assignments]
        .filter(assignment => !this.reviews.some(review => review.assignmentId === assignment.id))
    },
    getExistingReviews () {
      const filterReviews = assignment => this.reviews.find(review => review.assignmentId === assignment.id && !review.completedAt)
      const mapReviews = (assignment) => {
        assignment.review = this.reviews.find(review => review.assignmentId === assignment.id)
        assignment.lastThemeNumber = Object.prototype.hasOwnProperty.call(assignment.review, 'answers')
          ? assignment.review.answers.length
          : null
        assignment.lastTheme = assignment.lastThemeNumber
          ? `Theme ${assignment.lastThemeNumber}`
          : '-'
        return assignment
      }

      return [...this.assignments]
        .filter(filterReviews)
        .map(mapReviews)
    },
    async didClickStartReview (assignment) {
      // We have to ensure state is up to date before we can filter the assignments
      await this.$store.dispatch('assignment/fetchAssignmentsForCurrentUser')
      await this.$store.dispatch('assignment/fetchAssignments')

      const newAssignments = this.newAssignments
      const existingReviews = this.existingReviews

      const findAssignment = item => item.id === assignment.id

      const isNewAssignment = (newAssignments.find(findAssignment)) ? true : false
      const isExistingReview = (existingReviews.find(findAssignment)) ? true : false

      if (isNewAssignment && !isExistingReview) {
        this.didChooseExistingReview = false
        this.setCurrentAssignment(assignment)
      }

      if (!isNewAssignment && isExistingReview) {
        this.setCurrentAssignment(null)

        alert('You have already started a review for this plan. Please select from the list of reviews in progress.')
      }
    },
    didClickContinueReview (assignment) {
      this.didChooseExistingReview = true
      this.setCurrentAssignment(assignment)
      this.continueReview(assignment.review)
    },
    startAudit () {
      this.$router.push({ name: 'ReviewConfirmation' })
    },
    canNavigate () {
      return this.hasClickedNext
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.review-list {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.active {
  background: $brand-gradient !important;
  opacity: 0.8;
  color: #fff;
}
</style>