<template>
  <div class="review-template-container">
    <div class="title">
      <h3 class="section-title">{{ currentTheme.title }}</h3>
      <p class="section-subtitle">{{ currentTheme.description }}</p>
    </div>
    <div class="grades-row">
      <GradeButton
        v-for="grade in ['gold', 'silver', 'bronze']"
        :key="grade"
        :grade="grade"
        :selected="grade === activeGrade"
        activated="false"
      />
    </div>
    <div>
      <textarea
        class="textarea"
        placeholder="Please provide any other comments on the quality of this section"
        v-model="comments"
      />
    </div>
    <div class="evaluation">
      <p class="subtitle__bold">QA criteria</p>
      <ul class="question-list">
        <div class="question__wrapper">
          <p class="question__text">Q1. {{ themeCriteria[0].text }}</p>
          <select
            class="question__answer"
            @change="(event) => didSelectQualifyingAnswer(event, 0)"
            v-model="outcomesEvidenced"
          >
            <option disabled value="null">Please select an option</option>
            <option
              v-for="(answer, index) in themeCriteria[0].answers"
              :key="index"
              :value="answer.value"
            >
              {{ answer.title }}
            </option>
          </select>
        </div>
        <div v-if="outcomesEvidenced">
          <div class="question__wrapper">
            <p class="question__text">Q2. {{ themeCriteria[1].text }}</p>
            <select
              class="question__answer"
              @change="(event) => didSelectQualifyingAnswer(event, 6)"
              v-model="smartOutcomes"
            >
              <option disabled value="null">Please select an option</option>
              <option
                v-for="(answer, index) in themeCriteria[1].answers"
                :key="index"
                :value="answer.value"
              >
                {{ answer.title }}
              </option>
            </select>
          </div>
          <div class="question__wrapper">
            <p class="question__text">Q3. {{ themeCriteria[2].text }}</p>
            <select
              class="question__answer"
              @change="(event) => didSelectQualifyingAnswer(event, 7)"
              v-model="outcomesProgress"
            >
              <option disabled value="null">Please select an option</option>
              <option
                v-for="(answer, index) in themeCriteria[2].answers"
                :key="index"
                :value="answer.value"
              >
                {{ answer.title }}
              </option>
            </select>
          </div>
          <div class="question__wrapper">
            <p class="question__text">Q4. {{ themeCriteria[3].text }}</p>
            <select
              class="question__answer"
              @change="didSelectNumberOutcomes"
              v-model="numberOutcomes"
            >
              <option disabled value="0">Please select an option</option>
              <option
                v-for="(answer, index) in themeCriteria[3].answers"
                :key="index"
                :value="answer.value"
              >
                {{ answer.title }}
              </option>
            </select>
          </div>
        </div>
        <div
          class="repeated-questions"
          v-for="num in numberOutcomes"
          :key="num"
        >
          <div
            class="question__wrapper"
            v-for="(question, rIndex) in repeatedOutcomesQuestions[num - 1]"
            :key="rIndex"
          >
            <p class="question__text">{{ question.text }}</p>
            <select
              class="question__answer"
              @change="didSubmitRepeatAnswer($event, num, rIndex, question.questionIndex)"
              v-model="repeatedOutcomesResponses[num][rIndex]"
            >
              <option disabled value="null">Please select an option</option>
              <option
                v-for="(answer, aIndex) in question.answers"
                :key="aIndex"
                :value="answer.value"
              >
                {{ answer.title }}
              </option>
            </select>
          </div>
        </div>
      </ul>
    </div>
    <SectionFooter
      nextButtonText="View results"
      @backClicked="navigateToPreviousSection"
      @nextClicked="navigateToNextSection"
      :canClickNextButton="canNavigate()"
    />
  </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */

import { mapGetters, mapActions } from 'vuex'

import SectionFooter from '@/components/SectionFooter'
import GradeButton from '@/components/layout/GradeButton.vue'

export default {
  name: 'MonitoringTheme',
  data () {
    return {
      id: 6,
      boundary: {
        gold: 2.51,
        silver: 1.51,
        bronze: 1
      },
      outcomesEvidenced: null,
      smartOutcomes: null,
      outcomesProgress: null,
      selected: [],
      repeatedOutcomesResponses: {},
      numberOutcomes: 0,
      previousNumberOutcomes: 0,
      repeatedOutcomesQuestions: [],
      activeGrade: null
    }
  },
  beforeMount () {
    this.updateAnswers()
    // TODO:MF 
    // Still need to populate existing data into the outcome monitoring field (them 6)
    // As we don't have the index from the dropdown and the object may not be is the right order
    // Sort object by iteration and questionIndex
    // Get the select box answer based on the text
    // convert Yes No Answers to true or false for boolean selects
    //
    // const filteredCriteria = this.themeAnswers.criteria.filter(item => item.hasOwnProperty('iteration'));
    // const sortedFilteredCriteria = filteredCriteria.sort((a, b) => a.iteration - b.iteration)

    // console.log(sortedFilteredCriteria)
    // console.log(this.themeAnswers.criteria)
  },
  components: { GradeButton, SectionFooter },
  computed: {
    ...mapGetters({
      themes: 'criteria/themes',
      answers: 'review/answers'
    }),
    currentTheme () {
      return this.themes[this.id - 1]
    },
    themeCriteria () {
      return this.currentTheme.questions
    },
    themeAnswers () {
      return [...this.answers].find(a => a.theme === this.id) || {}
    },
    gradingScores () {
      // TODO: Make this pull from server
      return {
        gold: true,
        bronze: false
      }
    },
    setOutcomeEvidence () {
      console.log(this.repeatedOutcomesResponses)
    },  
    comments: {
      set (text) {
        this.updateThemeComment(text)
      },
      get () {
        return this.themeAnswers
          ? this.themeAnswers.comments
          : null
      }
    }
  },
  watch: {
    numberOutcomes (val) {
      this.updateOutcomes(val)
    },
    outcomesEvidenced (val) {
      if (!val) {
        this.outcomesProgress = null
        this.smartOutcomes = null
      }
      this.calculateScore()
    }
  },
  methods: {
    ...mapActions({ submitAnswer: 'review/submitAnswer', addComment: 'review/addComment' }),
    ...mapGetters({
      reviewId: 'review/reviewId'
    }),
    updateThemeComment: _.debounce(function (text) {
      this.addComment({ theme: this.id, text })
    }, 1000),
    getAnswer (questionIndex, iteration) {
      return this.themeAnswers.criteria.find((a) => {
        let isSameQuestion = questionIndex === a.questionIndex
        if (iteration != null) isSameQuestion = isSameQuestion && iteration === a.iteration
        return isSameQuestion
      })
    },
    calculateScore () {
      if ((!this.outcomesProgress && !this.smartOutcomes) || !this.outcomesEvidenced) return this.activeGrade = 'bronze'
      const score = this.themeAnswers.outcome || (this.outcomesProgress + this.smartOutcomes) / 2
      if (score >= this.boundary.gold) this.activeGrade = 'gold'
      else if (score >= this.boundary.silver) this.activeGrade = 'silver'
      else if (score >= this.boundary.bronze) this.activeGrade = 'bronze'
      else this.activeGrade = null
    },
    updateOutcomes (val) {
      const responses = {};
      this.repeatedOutcomesQuestions = []
      this.repeatedOutcomesResponses = []
      const outcomeQuestions = this.themeCriteria.slice(4,6)
      const childQuestions = this.themeCriteria.slice(6)

      for (let i = 1; i <= val; i++) {
        // Initialize empty array of null values to be populated later.
        const getValue = (q, i) => {
          const answer = this.getAnswer(q.questionIndex, i)
          const value = q.answers.find((a) => a.title === answer?.text)?.value
          return typeof value == 'undefined' ? null : value
        }
        const outcome = outcomeQuestions.map((question) => getValue(question, i))
        const question3 = this.getAnswer(3, i)
        if (question3 != null) {
          let childQuestionIndex = childQuestions[0]
          if (question3.text === 'No') childQuestionIndex = childQuestions[1]
          this.repeatedOutcomesQuestions.push([this.themeCriteria[4], this.themeCriteria[5], childQuestionIndex])
          const value = getValue(childQuestionIndex, i)
          if (value != null) outcome.push(value)
          else outcome.push(null)
        } else {
          this.repeatedOutcomesQuestions = [...this.repeatedOutcomesQuestions, this.themeCriteria.slice(4,6)]
        }
        responses[i] = outcome;
      }
      this.repeatedOutcomesResponses = responses
    },
    updateAnswers () {
      if (!this.themeAnswers.criteria || !this.themeAnswers.criteria[0].score) {
        return
      }
      const outcomesEvidencedAnswer = this.getAnswer(0)
      this.outcomesEvidenced = (outcomesEvidencedAnswer.score === -1) ? true : false

      if (!this.outcomesEvidenced) {
        return
      }

      this.smartOutcomes = this.getAnswer(6)?.score || null
      this.outcomesProgress = this.getAnswer(7)?.score || null
      this.numberOutcomes = this.getAnswer(1)?.text || 0
      this.calculateScore()
      
      if (this.numberOutcomes === 0) {
        return
      }
      
      this.updateOutcomes(this.numberOutcomes)
    },
    async didSelectAnswer (payload) {
      await this.submitAnswer(payload)
      this.updateAnswers()
    },
    // TODO: This function is not being used, but returns the correct answer as a string
    iterationSelected (iteration, questionIndex) {
      const answers = this.themeAnswers.criteria.filter(item => item.hasOwnProperty('iteration') && item.iteration === iteration && item.questionIndex === questionIndex)
      
      if (answers.length === 0) {
        return null
      }

      const { text } = answers[0]

      return text
    },
    didSelectQualifyingAnswer (event, questionIndex) {
      const text = event.target.options[event.target.options.selectedIndex].text
      
      let score = text === "Yes" ? 3 : text === "No" ? 1 : 2

      if (questionIndex === 0) {
        if (score === 1) this.numberOutcomes = 0
        if (score === 3) score = -1
      }

      this.didSelectAnswer({ theme: 6, answer: { questionIndex, score, text } })

      this.calculateScore()
    },
    didSelectNumberOutcomes () {
      const text = this.numberOutcomes

      this.didSelectAnswer({ theme: 6, answer: { questionIndex: 1, score: -1, text } })
    },
    didSubmitRepeatAnswer (event, iteration, index, questionIndex) {
      const themeObject = { theme: 6, answer: {} }
      const selectedAnswerIndex = event.target.options.selectedIndex

      // Question index should be the index number from the criteria array
      const answer = { 
        questionIndex,
        score: -1, 
        text: event.target.options[selectedAnswerIndex].text, 
        iteration 
      }
  
      this.didSelectAnswer({...themeObject, answer })

      // If its the first question in an question iteration then we need to update the next question depending on the answer
      if (questionIndex === 3) {
        const filterOutQuestionFourAndFive = (item) => item !== this.themeCriteria[6] && item !== this.themeCriteria[7]

        // Note the iteration and the array position are different
        const currentIterationArrayPosition = iteration - 1

        // Set the response at currentIterationObjectPosition to null
        this.repeatedOutcomesResponses[iteration][2] = null
        this.repeatedOutcomesQuestions[currentIterationArrayPosition] = this.repeatedOutcomesQuestions[currentIterationArrayPosition].filter(filterOutQuestionFourAndFive)

        if (selectedAnswerIndex === 1) {
          this.repeatedOutcomesQuestions[currentIterationArrayPosition].push(this.themeCriteria[6])
        } else {
          this.repeatedOutcomesQuestions[currentIterationArrayPosition].push(this.themeCriteria[7])
        }
      }
    },
    canNavigate () {
      if (!Array.isArray(this.themeAnswers.criteria)) {
        return false
      }

      // If there are no outcomes then let them complete the audit
      if (this.themeAnswers.criteria[0].score === 1) {
        return true
      }

      if (!this.numberOutcomes) {
        return false
      }

      // If there are any nulls then don't let them complete the audit
      let hasNull = Object.values(this.repeatedOutcomesResponses).some(arr => arr.includes(null));
      hasNull = hasNull || this.outcomesProgress == null || this.smartOutcomes == null

      if (hasNull) {
        // TODO: MF We should probably highlight the incomplete fields
        return false
      }

      return true
    },
    async refreshReview() {
      try {
        const reviewID = this.reviewId()
        await this.$store.dispatch('review/refreshReview', reviewID)
        this.updateAnswers()
      } catch (error) {
        alert('Something went wrong. Please start the review again.')

        this.$router.push({ name: 'ReviewList' })
      }
    },
    navigateToPreviousSection () {
      const currentThemeIndex = Number(this.id)

      // Refresh reviewState to ensure we have the latest data in the view
      this.refreshReview()

      if (currentThemeIndex === 1) {
        this.navigateToReviewConfirmation()
      } else {
        this.navigateToTheme(currentThemeIndex - 1)
      }
    },
    async navigateToNextSection () {
      // Refresh reviewState to ensure we have the latest data in the view
      this.refreshReview()

      if (!this.canNavigate()) {
        return
      }

      const currentThemeIndex = Number(this.id)

      if (currentThemeIndex === 6) {
        this.navigateToGradingSummary()
      } else {
        this.navigateToTheme(currentThemeIndex + 1)
      }
    },
    navigateToGradingSummary () {
      this.$router.push({ name: 'ReviewSummary' })
    },
    navigateToReviewConfirmation () {
      this.$router.push({ name: 'ReviewConfirmation' })
    },
    navigateToTheme (index) {
      this.$router.push({
        name: 'PerformReview',
        params: {
          id: Number(index)
        }
      })
    },
  },
  mounted () {
    try {
      this.refreshReview()
    } catch (error) {
      alert('Something went wrong. Please start the review again.')

      this.$router.push({ name: 'ReviewList' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.review-template-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.title {
  .section-title {
    margin: 0;
    font-weight: 600;
    font-size: 1.25rem;
  }
  .section-subtitle {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.4rem;
    &--bold {
      font-weight: 600;
    }
  }
}

.textarea {
  border-radius: $content-border-radius;
  border: $content-border;
  height: 100%;
  font-size: 1rem;
  outline: none;
  resize: none;
  overflow: auto;
  padding: 10px;
  width: 100%;
  margin-top: 15px;
}
textarea,
textarea::placeholder {
  font-family: AllRoundGothicBook;
}

.grades-row {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 2rem;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
}

.evaluation {
  margin-top: 1rem;
  .subtitle {
    &__bold {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }
  }
}

.repeated-questions {
  position: relative;
  height: 100%;
  padding-left: 1rem;
  .question__wrapper {
    position: relative;
    height: 100%;
  }
}
.repeated-questions::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 1rem;
  width: 2px;
  background: rgba(#000, 0.1);
}
</style>