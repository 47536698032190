<template>
  <div class="question-item-wrapper">
    <div class="question__wrapper">
      <p class="question__text">Q{{ index + 1 }}. {{ question.text }}</p>
      <div class="question__right">
        <select
          class="question__answer"
          @change="didSelectAnswer"
          v-model="selected"
        >
          <option disabled value="false" v-text="disabledOption" />

          <option
            v-for="(answer, index) in answers"
            :key="index"
            :value="answer.value"
          >
            {{ answer.title }}
          </option>
        </select>
        <span class="arrow" v-if="selected == 2" >
          <i class="fas fa-comment-slash fa-disabled" v-if="selected == 2"/>
        </span>
      </div>
    </div>
    <div class="answer-comments" :style="getCommentWrapperStyle">
      <span>Explanatory comments (mandatory for partially met)</span>
      <textarea v-model="comment" placeholder="Please provide additional information" />
    </div>
  </div>
</template>

<script>
import _ from "lodash"

export default {
  props: ['question', 'index', 'answer', 'dbComment'],
  mounted () {
    if (this.dbComment && !this.comment)
      this.comment = this.dbComment
  },
  data () {
    return {
      disabledOption: 'Please select an option',
      value: false,
      comment: null,
      showingComments: false
    }
  },
  computed: {
    selected: {
      get () {
        
        if (this.answer === null || this.answer === undefined) {
          return false
        }

        return this.answer
      },
      set (value) {
        this.value = value
      }
    },
    themeId () {
      return this.$route.params.id || 6
    },
    answers () {
      return this.question.answers
    },
    getCommentWrapperStyle() {
      if (this.selected==2 || this.showingComments) {
        return 'max-height: 100px;'
      } else {
        this.comment = null
        
        return ''
      }
    }
  },
  methods: {
    didSelectAnswer (event) {
      const text = event.target.options[event.target.options.selectedIndex].text
      const score = Number(event.target.value)

      const params = { 
        theme: this.themeId, 
        answer: { 
          questionIndex: this.index, 
          score, 
          text, 
          comments: this.comments 
        } 
      }

      this.$emit('did-answer', params)
    },
    updateQuestionComments: _.debounce(function (params) {
      this.$emit('did-answer', params)
    }, 300)
  },
  watch : {
    comment (comments) {
      let { answers, selected } = this
      
      if (!comments) {
        comments = this.dbComment
      }
      
      const text = (answers.find(({ value }) => value == selected) || {}).text || undefined
      const score = Number(selected)

      const params = { 
        theme: this.themeId, 
        answer: { 
          questionIndex: this.index, score, text, comments 
        } 
      }

      this.updateQuestionComments(params)
    },
    dbComment (val) {
      this.comment = this.dbComment
    }
  }
}
</script>

<style lang="scss">
.question {
  &__wrapper {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0.75rem;
    transition: all 300ms ease-in-out;

    .fa-disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
    .arrow {
      float: right;
    }
  }

  &__text {
    flex-basis: auto;
  }
  &__answer {
    cursor: pointer;
    flex-basis: auto;
    height: 2rem;
    margin-left: 1rem;
    margin-right: 8px;
    padding: 4px;
  }
  &__right {
    min-width: 248px;
  }
}

.answer-comments {
  max-height: 0;
  transition: all ease-in-out 0.2s;
  overflow: hidden;
  margin: 0;
  padding: 0 0.75rem;

  textarea {
    width: 100%;
  }
}
</style>
