<template>
  <div class="review-selection-confirmation">
    <SectionHeader
      title="Child Annual Review details"
      subtitle="You have selected the following plan to audit:"
    />
    <!-- List of Plans -->
    <div class="ehcp-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>*C/YP ID</th>
              <th>Key Stage</th>
              <th>Primary Need</th>
              <th>Setting Name</th>
              <th>Setting Type</th>
              <th>Review Date</th>
              <th>Current Social Care</th>
              <th>SEN Case Officer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ currentPlan.internalId || currentPlan.ehcpId }}</td>
              <td>{{ currentPlan.keyStage }}</td>
              <td>{{ currentPlan.primaryNeed }}</td>
              <td>{{ currentPlan.settingName }}</td>
              <td>{{ currentPlan.settingType }}</td>
              <td>{{ currentPlan.finalisedDate | formatDate }}</td>
              <td>{{ currentPlan.currentSocialCare }}</td>
              <td>{{ currentPlan.caseOfficer }}</td>
            </tr>
          </tbody>
        </table>
        <div class="intro-text">
          <p>
            Please confirm the details above are correct before proceeding to
            complete the section judgements
          </p>
        </div>
      </div>
    </div>
    <SectionFooter
      nextButtonText="Confirm and proceed"
      :canClickNextButton="!canNavigate()"
      @backClicked="navigateToReviewList"
      @nextClicked="startAudit"
    />
  </div>
</template>

<script>
import SectionFooter from '@/components/SectionFooter'
import SectionHeader from '@/components/SectionHeader'
import DateFilterMixin from '@/_mixins/date-filter.mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ReviewList',
  data: () => ({
    hasClickedNext: false,
  }),
  mixins: [DateFilterMixin],
  components: { SectionHeader, SectionFooter },
  computed: {
    ...mapGetters({
      assignments: 'assignment/assignmentsForCurrentUser',
      currentAssignment: 'assignment/currentAssignment',
      reviews: 'assignment/reviews',
    }),  
    currentPlan () {
      return this.currentAssignment.plan
    }
  },
  methods: {
    ...mapActions({ 
      startReview: 'review/startReview',
      continueReview: 'review/continueReview', 
    }),
    navigateToReviewList () {
      this.$router.push({ name: 'ReviewList' })
    },
    async startAudit () {
      if (this.hasClickedNext) return

      this.hasClickedNext = true

      // Force update of state so we can check if the review has been started
      await this.$store.dispatch('assignment/fetchAssignmentsForCurrentUser')
      await this.$store.dispatch('assignment/fetchAssignments')

      // Check if the current assignment has already been started
      const existingReviews = this.getExistingReviews()
      const findAssignment = (item) => item.id === this.currentAssignment.id
      const isExistingReview = (existingReviews.find(findAssignment)) ? true : false

      // If the review has already been started but does not have the review property, alert the user and redirect to the review list
      if (isExistingReview && !this.currentAssignment.hasOwnProperty('review')) {
        alert('This review has already been started, please select again from the review in progress list.')
    
        this.navigateToReviewList()

        return 
      }


      if (!this.currentAssignment.hasOwnProperty('review')) {
        await this.startReview({ assignmentId: this.currentAssignment.id, plan: this.currentAssignment.plan })
      }

      const startAtTheme = Object.prototype.hasOwnProperty.call(this.currentAssignment, 'lastThemeNumber')
        ? this.currentAssignment.lastThemeNumber > 0 ? this.currentAssignment.lastThemeNumber : 1
        : 1

      const routerPath = startAtTheme === 6
        ? { name: 'MonitoringTheme' }
        : { name: 'PerformReview', params: { id: Number(startAtTheme) } }
        
      this.$router.push(routerPath)
    },
    getExistingReviews () {
      const filterReviews = assignment => this.reviews.find(review => review.assignmentId === assignment.id && !review.completedAt)
      const mapReviews = (assignment) => {
        assignment.review = this.reviews.find(review => review.assignmentId === assignment.id)
        assignment.lastThemeNumber = Object.prototype.hasOwnProperty.call(assignment.review, 'answers')
          ? assignment.review.answers.length
          : null
        assignment.lastTheme = assignment.lastThemeNumber
          ? `Theme ${assignment.lastThemeNumber}`
          : '-'
        return assignment
      }

      return [...this.assignments]
        .filter(filterReviews)
        .map(mapReviews)
    },
    canNavigate () {
      return this.hasClickedNext
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";

.review-selection-confirmation {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .dashboard-section-footer {
    margin-top: auto;
  }
}

.intro-text {
  padding: 2rem;
  p {
    font-weight: 600;
  }
}
</style>